<template>
  <v-container>
    <v-row class="pa-4" justify="space-between">
      <div>
        <h2 class="primary--text">
          Dossiers
        </h2>
        <span>{{ filtered_dossiers.length }} dossier(s) sélectionné(s)</span>
      </div>
      <div>
        <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
      </div>
      <v-btn to="/dossier/create" color="secondary" class="white--text">
        <v-icon>mdi-plus</v-icon>
        Créer un dossier
      </v-btn>

    </v-row>
    <h2 class="pa-0">Champs actifs</h2>
    <v-checkbox dense v-model="active_fields.statut_pret" label="Étude de prêt"></v-checkbox>
    <v-checkbox dense v-model="active_fields.numero_bpi" label="Numéro BPI"></v-checkbox>
    <v-checkbox dense v-if="!isMaitreOeuvre" v-model="active_fields.conducteur_travaux" label="Conducteur de travaux"></v-checkbox>
    <v-checkbox dense v-model="active_fields.maitre_oeuvre" label="Maitre d'oeuvre"></v-checkbox>
    <v-row class="my-2" justify="space-around" align="center" v-if="showScroll">
      <v-icon @click="scrollLeft" color="primary">mdi-chevron-left</v-icon>
      <v-spacer></v-spacer>
      <v-icon @click="scrollRight" color="primary">mdi-chevron-right</v-icon>
    </v-row>
    <v-simple-table ref="table" class="table-height overflow-x-auto overflow-y-auto">
      <template v-slot:default>
        <thead>
        <tr class="primary">
          <th class="white--text text-left">Numéro de dossier</th>
          <th class="white--text text-left">Nom</th>
          <th class="white--text text-left">Prénom</th>
          <th class="white--text text-left">Référence</th>
          <th class="white--text text-left">Statut</th>
          <th v-if="active_fields.statut_pret" class="white--text text-left">Statut de prêt</th>
          <th class="white--text text-left">Type de dossier</th>
          <th class="white--text text-left">Ville du bien immobilier</th>
          <th class="white--text text-left">Montant des travaux</th>
          <th class="white--text text-left">Numéro d'arrêté</th>
          <th v-if="active_fields.numero_bpi" class="white--text text-left">Numéro BPI</th>

          <th class="white--text text-left">Instructeur</th>
          <th class="white--text text-left">Enquêteur</th>
          <th v-if="active_fields.conducteur_travaux && !isMaitreOeuvre" class="white--text text-left">Conducteur de travaux</th>
          <th v-if="active_fields.maitre_oeuvre" class="white--text text-left">Maitre d'oeuvre</th>
          <th class="white--text"></th>
        </tr>
        <tr>
          <th class="text-left">
            <v-text-field background-color="white" v-model="filters.id" label="ID"></v-text-field>
          </th>
          <th class="text-left">
            <v-text-field background-color="white" v-model="filters.last_name"
                          label="Nom"></v-text-field>
          </th>
          <th class="text-left">
            <v-text-field background-color="white" v-model="filters.first_name"
                          label="Prénom"></v-text-field>
          </th>
          <th class="text-left">
            <v-text-field background-color="white" v-model="filters.reference" label="Référence"></v-text-field>
          </th>
          <th class="text-left">
            <v-select background-color="white" label="Statut" v-model="filters.statut" :items="statuts"
                      multiple deletable-chips chips small-chips
                      item-text="name"
                      item-value="id"></v-select>
          </th>
          <th v-if="active_fields.statut_pret" class="text-left">
            <v-select background-color="white" label="Statut de prêt" v-model="filters.statut_pret"
                      :items="statut_pret_select"
                      item-text="name"
                      item-value="value"></v-select>
          </th>
          <th class="text-left">
            <v-select :items="types_dossier" v-model="filters.type_dossier"></v-select>
          </th>
          <th class="text-left">
            <v-select v-model="filters.ville" multiple small-chips deletable-chips background-color="white"
                      label="Villes" :items="villes" item-text="name"
                      item-value="value"></v-select>
          </th>
          <!--            <th class="text-left">
                        <v-text-field v-model="reference_filter" background-color="white" label="Référence"></v-text-field>
                      </th>-->
          <th>
            <v-range-slider
                :hint="montant_filter_display"
                v-model="filters.montant"
                max="120000"
                min="0"
            ></v-range-slider>
          </th>
          <th>
            <v-select :items="years" v-model="filters.arrete" label="Numéro arrêté"></v-select>
          </th>
          <th v-if="active_fields.numero_bpi" class="text-left">
            <v-text-field v-model="filters.numero_bpi" label="Numéro BPI"></v-text-field>
          </th>
          <th class="text-left">
            <v-select v-model="filters.instructeur" background-color="white" label="Instructeur" :items="instructeurs"
                      multiple small-chips deletable-chips
                      item-text="fullname"
                      item-value="id"></v-select>
          </th>
          <th class="text-left">
            <v-select v-model="filters.enqueteur" item-text="value" item-value="value" background-color="white" label="Enquêteur" :items="enqueteurs"
                      multiple small-chips deletable-chips></v-select>
          </th>
          <th v-if="active_fields.conducteur_travaux && !isMaitreOeuvre" class="text-left">
            <v-select v-model="filters.conducteur_travaux" background-color="white" label="Conducteurs de travaux" :items="conducteurs_travaux"
                      multiple small-chips deletable-chips
                      item-text="fullname"
                      item-value="id"></v-select>
          </th>
          <th v-if="active_fields.maitre_oeuvre" class="text-left">
            <v-select v-model="filters.maitre_oeuvre" background-color="white" label="Maitre d'oeuvre" :items="maitres_oeuvre"
                      multiple small-chips deletable-chips
                      item-text="fullname"
                      item-value="id"></v-select>
          </th>

          <th>
            <v-tooltip bottom color="white">
              <template v-slot:activator="{on, attrs}">
                <v-btn @click="removeFilters" v-on="on" v-bind="attrs" color="red" text>
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
              <span class="text-uppercase red--text">Effacer les filtres</span>
            </v-tooltip>
          </th>
        </tr>
        <tr v-if="selected_dossiers.length > 0" style="background: #A5D8D2">
          <td colspan="1">
            <v-checkbox v-model="selectAll" label="Sélectionner tout"></v-checkbox>
          </td>
          <td colspan="2" class="text-center">
            <h3 class="primary--text">Modifier plusieurs dossiers</h3>
          </td>
          <td colspan="2">
            <v-select background-color="white" return-object label="Statut" v-model="statutMultiple" :items="statuts"
                      item-text="name"
                      item-value="id"></v-select>
          </td>
          <td colspan="2">
            <v-select v-model="instructeurMultiple" background-color="white" label="Instructeur" :items="instructeurs"
                      item-text="fullname"
                      item-value="id"></v-select>
          </td>
          <td colspan="2">
            <v-btn @click="editMultipleDossiers" :disabled="editing" :color="editing ? 'grey' : 'primary'" text>
              <v-icon>mdi-pencil</v-icon>
              Modifier les dossiers sélectionnés
            </v-btn>
            <v-progress-circular indeterminate color="primary" v-if="editing"></v-progress-circular>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(dossier, index) in filtered_dossiers" :key="index">
          <td>

            <v-row justify="center" align="center">
              <v-tooltip left>
                <template v-slot:activator="{on, attrs}">
                  <div v-bind="attrs" v-on="on">
                    <v-checkbox class="white mt-0 d-inline" v-if="isIntern || isAdmin" v-model="selected_dossiers"
                                :value="dossier.id"></v-checkbox>
                  </div>
                </template>
                <span class="white--text">Modifier le dossier</span>
              </v-tooltip>
              <h4 class="secondary--text">
                <router-link class="text-decoration-none secondary--text" :to="`/dossier/${dossier.id}`">
                  #{{ dossier.id }}
                </router-link>
              </h4>
            </v-row>

          </td>
          <td>
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <h4 class="primary--text" v-bind="attrs" v-on="on">
                  <router-link class="text-decoration-none" :to="`/dossier/${dossier.id}`">{{
                      dossier.beneficiaire_last_name
                    }}
                  </router-link>
                </h4>
              </template>
              <h4 class="white--text">
                {{
                  dossier.beneficiaire_last_name
                }}
              </h4>
            </v-tooltip>
          </td>
          <td>
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <h4 class="primary--text" v-bind="attrs" v-on="on">
                  <router-link class="text-decoration-none" :to="`/dossier/${dossier.id}`">{{
                      dossier.beneficiaire_first_name
                    }}
                  </router-link>
                </h4>
              </template>
              <h4 class="white--text">
                {{
                  dossier.beneficiaire_first_name
                }}
              </h4>
            </v-tooltip>
          </td>
          <td>
            <span class="grey--text">{{ dossier.reference }}</span>
          </td>
          <td>
            <v-chip class="blue accent-4 white--text mt-n1">{{ dossier.current_statut }}</v-chip>
          </td>
          <td v-if="active_fields.statut_pret">
            <span class="grey--text">{{ dossier.statut_pret }}</span>
          </td>
          <td>{{dossier.type_dossier}}</td>
          <td><span class="grey--text">{{ dossier.beneficiaire_ville }}</span></td>
          <td><span>{{ dossier.montant_travaux }} €</span></td>
          <td><span>{{ dossier.numero_arrete }}</span></td>
          <td v-if="active_fields.numero_bpi"><span>{{ dossier.numero_bpi }}</span></td>
          <td>
            <v-chip class="primary white--text">
              <span v-if="dossier.instructeur !== null">{{
                  dossier.instructeur.first_name
                }} {{ dossier.instructeur.last_name }}</span>
              <span v-else>Non défini</span>
            </v-chip>
          </td>
          <td>
            <v-chip v-if="dossier.enqueteur" class="grey darken-3 white--text">
              {{ dossier.enqueteur_name }}
            </v-chip>
          </td>
          <td v-if="active_fields.conducteur_travaux && !isMaitreOeuvre">
            <v-chip class="primary white--text">
              <span v-if="dossier.conducteur_travaux !== null">{{
                  dossier.conducteur_travaux.first_name
                }} {{ dossier.conducteur_travaux.last_name }}</span>
              <span v-else>Non défini</span>
            </v-chip>
          </td>
          <td v-if="active_fields.maitre_oeuvre">
            <v-chip class="primary white--text">
              <span v-if="dossier.maitre_oeuvre !== null">{{
                  dossier.maitre_oeuvre.first_name
                }} {{ dossier.maitre_oeuvre.last_name }}</span>
              <span v-else>Non défini</span>
            </v-chip>
          </td>
          <td></td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import {AuthMixin} from "@/mixins/AuthMixin";
import {convertStringListToSelect} from "@/util";
import {VILLES} from "@/constants/Villes";
import {DateUtilMixin} from "@/mixins/DateUtilMixin";
import dayjs from "dayjs";

const TYPES_DOSSIER = [
    "AAH",
    "LLS",
    "LLTS",
    "MaPrimeAdapt",
    "SOLIHA"
]
export default {
  name: 'Dossiers',
  mixins: [AuthMixin, DateUtilMixin],
  async mounted() {
    this.loading = true;
    if (this.$store.getters["dossier/getDossiers"].length === 0) {
      await this.$store.dispatch("dossier/fetchDossiers");
    }
    if (this.$store.getters["select/selects"].length === 0) {
      await this.$store.dispatch("select/fetchSelects");
    }
    let arrete = this.$route.query?.arrete;
    let statut = this.$route.query?.statut;
    if (localStorage.getItem("filters") && arrete === undefined && statut === undefined) {
      try {
        this.filters = JSON.parse(localStorage.getItem("filters"));
      } catch (e) {
        this.filters = {
          id: null,
          last_name: "",
          first_name: "",
          reference: "",
          statut: [],
          statut_pret: null,
          ville: [],
          montant: [0, 100000],
          arrete: null,
          instructeur: [],
          enqueteur: [],
          maitre_oeuvre: [],
          conducteur_travaux: []
        }
      }
    }
    if (arrete !== undefined) {
      if (arrete === "absent") {
        this.filters.statut = this.statuts
            .filter((statut_obj) => statut_obj.numerical_value >= 2
                && statut_obj.numerical_value < 10)
            .map((s) => s.id);
      } else {
        try {
          this.filters.arrete = parseInt(arrete);
        } catch (e) {
          this.filters.arrete = null;
        }
      }
    }
    let statusFetchStatus = this.$store.getters["dossier/statuts/statut_fetch_status"];
    if (statusFetchStatus === "INITIAL" || statusFetchStatus === "ERROR") {
      await this.$store.dispatch("dossier/statuts/getStatuts");
    }
    if (statut !== undefined) {
      if (statut === "chantier") {
        this.filters.statut = this.statuts
            .filter((statut_obj) => statut_obj.numerical_value >= 12
                && statut_obj.numerical_value <= 15
                && statut_obj.name.indexOf("14.2") === -1)
            .map((s) => s.id);
      } else if (statut === "prospects") {
        this.filters.statut = this.statuts
            .filter((statut_obj) => statut_obj.numerical_value === 1)
            .map((s) => s.id);
      } else {
        this.filters.statut = [parseInt(statut)]
      }
    }
    let userFetchStatus = this.$store.getters["users/loading_status"];
    if (userFetchStatus === "INITIAL" || userFetchStatus === "ERROR") {
      await this.$store.dispatch("users/fetchUsers");
    }
    this.loading = false;
    await this.$nextTick();
    this.table = document.querySelector(".v-data-table__wrapper");
  },
  data() {
    return  {
      table: null,
      reference: "",
      dialog: false,
      loading: false,
      editing: false,
      selected_dossiers: [],
      statutMultiple: null,
      instructeurMultiple: null,
      viewStyle: 'table',
      selectAll: false,
      active_fields: {
        statut_pret: false,
        numero_bpi: false,
        conducteur_travaux: false,
        maitre_oeuvre: false,
      },
      filters: {
        id: null,
        conducteur_travaux: [],
        maitre_oeuvre: [],
        last_name: "",
        first_name: "",
        reference: "",
        numero_bpi: "",
        type_dossier: "",
        statut: [],
        statut_pret: null,
        ville: [],
        montant: [0, 120000],
        arrete: null,
        instructeur: [],
        enqueteur: [],
      }
    }
  },
  computed: {
    dossiers() {
      return this.$store.getters["dossier/getDossiers"];
    },
    filtered_dossiers() {
      return this.dossiers
          .filter((dossier) => this.filters.statut.length !== 0 ? this.filters.statut.indexOf(dossier.statut_id) !== -1 : true)
          .filter((dossier) => this.filters.first_name !== "" ? dossier.beneficiaire_first_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.filters.first_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) : true)
          .filter((dossier) => this.filters.last_name !== "" ? dossier.beneficiaire_last_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.filters.last_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) : true)
          .filter((dossier) => this.filters.reference !== "" ? dossier.reference.toLowerCase().includes(this.filters.reference.toLowerCase()) : true)
          .filter((dossier) => this.filters.instructeur.length !== 0 && !this.filters.instructeur.includes("") ? this.filters.instructeur.includes(dossier.instructeur?.id) : true)
          .filter((dossier) => this.filters.enqueteur.length !== 0 && !this.filters.enqueteur.includes("") ? this.filters.enqueteur.includes(dossier.enqueteur) : true)
          .filter((dossier) => this.filters.ville.length !== 0 ? this.filters.ville.includes(dossier.beneficiaire_ville) : true)
          .filter((dossier) => this.filters.id !== null && this.filters.id !== "" ? dossier.id.toString() === this.filters.id : true)
          .filter((dossier) => dossier.montant_travaux >= this.filters.montant[0] && this.filters.montant[1] >= dossier.montant_travaux)
          .filter((dossier) => this.filters.arrete !== null ? this.filters.arrete === "Sans arrêté" ? dossier.numero_arrete === null : dossier.numero_arrete != null && dossier.numero_arrete?.startsWith((parseInt(this.filters.arrete) - 2000).toString()) : true)
          .filter((dossier) => this.filters.statut_pret !== null ? dossier.statut_pret === this.filters.statut_pret : true)
          .filter((dossier) => this.filters.conducteur_travaux.length !== 0 && !this.filters.conducteur_travaux.includes("") ? this.filters.conducteur_travaux.includes(dossier.conducteur_travaux?.id) : true)
          .filter((dossier) => this.filters.maitre_oeuvre.length !== 0 && !this.filters.maitre_oeuvre.includes("") ? this.filters.maitre_oeuvre.includes(dossier.maitre_oeuvre?.id) : true)
          .filter((dossier) => this.filters.numero_bpi !== "" && this.filters.numero_bpi !== null && this.active_fields.numero_bpi ? dossier.numero_bpi != null && dossier.numero_bpi.indexOf(this.filters.numero_bpi) !== -1 : true)
          .filter((dossier) => this.filters.type_dossier !== "" ? dossier.type_dossier === this.filters.type_dossier : true)
    },
    statuts() {
      return this.$store.getters["dossier/statuts/statuts"];
    },
    aTestNumeroBpi() {
      return this.dossiers.map((dossier) => {
        return this.filters.numero_bpi !== "" && this.filters.numero_bpi !== null && this.active_fields.numero_bpi ? dossier.numero_bpi !== null && dossier.numero_bpi.indexOf(this.filters.numero_bpi) !== -1 : true;
      })
    },
    statut_pret_select() {
      let statutsPret = this.$store.getters["select/statut_pret_types"].map((statut) => ({
        value: statut.value,
        name: statut.value
      }));
      return [{value: null, name: 'Tout les statuts'}, ...statutsPret];
    },
    instructeurs() {
      return [{fullname: "Tous les instructeurs", id: ""}, ...this.$store.getters["users/instructeurs"]];
    },
    enqueteurs() {
      return ["", ...this.$store.getters["select/enqueteurs"]];
    },
    conducteurs_travaux() {
      return [{fullname: "Tous les conducteurs de travaux", id: ""}, ...this.$store.getters["users/conducteur_travaux"]];
    },
    maitres_oeuvre() {
      return [{fullname: "Tous les maitres d'oeuvre", id: ""}, ...this.$store.getters["users/maitres_oeuvre"]];
    },
    years() {
      return ["Sans arrêté", ...this.dossiers.filter((d) => d.date_arrete !== null).map((d) => dayjs(d.date_arrete).year())];
    },
    villes() {
      return [{
        value: null,
        name: "Toutes les villes"
      }, ...convertStringListToSelect(Object.keys(VILLES["Martinique"]))];
    },
    montant_filter_display() {
      return `${this.filters.montant[0]} € - ${this.filters.montant[1]} €`
    },
    showScroll() {
      return this.table !== null ? this.table.scrollWidth > this.table.clientWidth : false;
    },
    types_dossier() {
      return TYPES_DOSSIER.concat("");
    }
  },
  methods: {
    createDossier() {
      if (this.$refs.createDossierForm.validate()) {
        let dossier = {
          reference: this.reference,
          instructeur: this.logged_user.id,
        }
        this.$store.dispatch("dossier/postDossier", dossier);
        this.$store.dispatch("annonce/annonceSuccess", "Dossier créé.", {root: true})
        this.dialog = false;
      }
    },
    findStatut(statut) {
      let statut_text = this.statut_select.find((item) => item.value === statut);
      return statut_text != null ? statut_text : statut;
    },
    removeFilters() {
      this.filters.statut = [];
      this.filters.statut_pret = null;
      this.filters.first_name = "";
      this.filters.last_name = "";
      this.filters.reference = "";
      this.filters.instructeur = [];
      this.filters.type_dossier = "";
      this.filters.ville = [];
      this.filters.id = null;
      this.filters.montant = [0, 120000];
      this.filters.enqueteur = [];
      this.filters.arrete = null;
    },
    async editMultipleDossiers() {
      this.editing = true;
      let dossierUpdate = {};
      if (this.statutMultiple !== null) {
        dossierUpdate.statut = this.statutMultiple.id;
      }
      if (this.instructeurMultiple !== null) {
        dossierUpdate.instructeur = this.instructeurMultiple;
      }
      for (let dossier_id of this.selected_dossiers) {
        await this.$store.dispatch("dossier/patchDossier", {id: dossier_id, ...dossierUpdate});
      }
      await this.$store.dispatch("dossier/fetchDossiers");
      this.loading = false;
      this.selected_dossiers = [];
      this.statutMultiple = null;
      this.instructeurMultiple = null;
      this.editing = false;
      await this.$store.dispatch("annonce/annonceSuccess", "Dossiers mis à jour.");
    },
    scrollRight() {
      if (!this.table) return;
      this.table.scrollTo({top: this.table.clientHeight, left: this.table.scrollWidth, behavior: "smooth"});
    },
    scrollLeft() {
      if (!this.table) return;
      this.table.scrollTo({top: this.table.clientHeight, left: 0, behavior: "smooth"});
    }
  },
  watch: {
    selectAll(value) {
      if (value === true) {
        this.selected_dossiers = this.filtered_dossiers.map((dossier) => dossier.id);
      } else {
        this.selected_dossiers = [];
      }
    },
    selected_dossiers(value) {
      if (value === null) {
        this.selected_dossiers = [];
      }
    },
    instructeur_filter(value, oldvalue) {
      if (value.indexOf("") !== -1 && oldvalue.indexOf("") === -1) {
        this.instructeur_filter = [""];
      }
      if (oldvalue.indexOf("") !== -1 && oldvalue.length === 1 && value.length > 1) {
        this.instructeur_filter = this.instructeur_filter.filter(item => item !== "");
      }
    },
    enqueteur_filter(value, oldvalue) {
      if (value.indexOf("") !== -1 && oldvalue.indexOf("") === -1) {
        this.enqueteur_filter = [""];
      }
      if (oldvalue.indexOf("") !== -1 && oldvalue.length === 1 && value.length > 1) {
        this.enqueteur_filter = this.enqueteur_filter.filter(item => item !== "");
      }
    }
  },
  beforeDestroy() {
    localStorage.setItem("filters", JSON.stringify(this.filters));
  }
}
</script>
<style scoped>
/* Media query for screens with a maximum width of 600 pixels */
@media screen and (max-width: 600px) {
  .table-height {
    height: 70vh !important;
  }
}

/* Media query for screens with a minimum width of 601 pixels and a maximum of 900 pixels */
@media screen and (min-width: 601px) and (max-width: 900px) {
  .table-height {
    height: 60vh !important;
  }
}

/* Media query for screens with a minimum width of 901 pixels */
@media screen and (min-width: 901px) {
  .table-height {
    height: 65vh !important;
  }
}
</style>
